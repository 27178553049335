(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/payment-middlewares/assets/middleware-transfer-rows/transfer-rows-setup.js') >= 0) return;  svs.modules.push('/components/tipsen/payment-middlewares/assets/middleware-transfer-rows/transfer-rows-setup.js');
"use strict";

const {
  dialog
} = svs.components;
const {
  Confirm,
  branding,
  type
} = dialog;
const {
  transferRows,
  utils: transferUtils
} = svs.components.payment.middleware.transferRows;
const transferRowsMW = gameUrlOptions => transferRows(transferContext => new Promise(resolve => {
  const {
    transferUrl,
    title,
    onCallback
  } = transferContext;
  const dialogConfig = {
    branding: branding.SPORT,
    type: type.DEFAULT,
    icon: 'nav-my-games',
    title,
    actions: [{
      title: 'Nej tack',
      showLoader: true,
      callback() {
        onCallback(transferUtils.TRACKING_ACTIONS.CANCEL_TRANSFER);
        dialog.api.close();
        resolve();
      }
    }, {
      title: 'Överför',
      showLoader: true,
      callback() {
        onCallback(transferUtils.TRACKING_ACTIONS.TRANSFER);
        location.href = transferUrl;
        dialog.api.close();
        resolve();
      }
    }]
  };
  dialog.api.add(new Confirm(dialogConfig));
}), {
  gameUrlOptions
});
setGlobal('svs.components.paymentMiddlewares.middlewareTransferRows.transferRowsMW', transferRowsMW);

 })(window);